@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

html,
body {
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #979797;
  font-size: 35px;
  font-weight: 600;
  // font-family: "intro_regular", sans-serif;
}

p {
  font-size: 18px;
  color: #979797;
  line-height: 1.8;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a,
a:active,
a:focus {
  text-decoration: none !important;
  outline: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
}

.mb-30 {
  margin-bottom: 30px;
}

button {
  &.btn-back,
  &.btn-switch-lang {
    cursor: pointer;
  }
}

textarea{
  resize: none;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    .alert-link {
      color: #0b2e13;
    }
  }
  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    .alert-link {
      color: #491217;
    }
  }
}
/*------------------------
    Header Section
--------------------------*/
.header-section {
  padding-top: 0px;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
  }

  .site-logo {
    width: 130px;
    height: 100%;
    margin: 5px 0 0 0;
    text-align: center;
    img {
      width: 200px;
      height: 92px;
    }
  }
}

.main-menu {
  ul {
    list-style: none;
    height: 100%;
    display: flex;
    margin: 0;
    li {
      display: inline-flex;
      align-items: center;

      a {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        line-height: 1.5;
        color: #001418;
        padding: 0 15px;
        border-bottom: 1px solid transparent;
        span {
          display: block;
        }
        &.active,
        &:hover {
          border-bottom: 1px solid #001418;
        }
      }
    }
  }
}

.nav-switch {
  display: none;
}

.container {
  margin-top: 100px;

  &.homeLarge {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    .videoPlayer {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }

    h4 {
      text-align: center;
    }
  }
}

/*------------------------
  Global
--------------------------*/

h1 {
  font-size: 30px;
  &.section-title {
    color: #979797;
    margin: 0 0 20px 0;
    span {
      display: block;
      text-align: center;
      color: #979797;
    }
  }
}

.container {
  &.home {
    max-width: 100%;
    margin: 0;
    padding: 100px 0 0 0;
    .media {
      .videoPlayer {
        width: 100%;
      }
    }
    .description {
      margin-top: 40px;
      text-align: justify;
    }
  }

  .element {
    a {
      &.item {
        display: block;
        width: 100%;
        height: 300px;
        margin-bottom: 30px;
        position: relative;
        background-position: center center;
        -webkit-transition: all 0.4s ease 0.4s;
        -o-transition: all 0.4s ease 0.4s;
        transition: all 0.4s ease 0.4s;
        .pi-inner {
          position: absolute;
          width: 100%;
          height: 25%;
          left: 0;
          bottom: 0;
          background: transparent;
          h2 {
            opacity: 0;
            font-size: 15px;
            font-weight: 400;
            position: absolute;
            bottom: 20px;
            left: calc(50% - 50px);
            color: #fff;
            letter-spacing: 5px;
            -webkit-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
          }
        }
      }
      &:hover {
        .pi-inner {
          background: rgba(0, 20, 24, 0.8);
          h2 {
            opacity: 1;
            letter-spacing: 2px;
          }
        }
      }
    }
  }

  .meta {
    padding-bottom: 35px;
    h2 {
      margin: 0;
      font-size: 20px;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: #a6a6a6;
      margin-bottom: 0;
    }
  }
  button {
    color: #979797;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;

    background: none;
    border: none;
    &:focus {
      outline: none;
    }

    &.btn-switch-lang {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

/*------------------------
ABOUT US
-------------------------*/
.about-us {
  font-family: "Montserrat", sans-serif;

  .top {
    margin: 0 auto 50px auto;
    text-align: center;
    h1 {
      color: #979797;
      text-align: center;
      margin-top: 0;
      span {
        font-size: 40px;
        font-style: normal;
      }
    }

    span {
      font-size: 14px;
      font-style: italic;
    }
  }

  .description {
    text-align: justify;
    img {
      padding-bottom: 15px;
    }
  }

  .process {
    h2 {
      text-align: center;
      font-size: 30px;
      margin: 50px 0 25px 0;
    }
    img {
      padding: 15px;
      object-fit: cover;
    }
  }
}

/*------------------------
CATALOG
--------------------------*/
.catalog {
  .description {
    margin-bottom: 30px;
  }
  .area {
    margin-bottom: 30px;
  }
}

/*------------------------
  Media
--------------------------*/

.card-description {
  a {
    span {
      color: #333;
      font-size: 14px;
    }
  }
}

/*------------------------
  Footer
--------------------------*/
footer {
  padding: 60px 0;

  &.footer-section {
    .container-fluid {
      .container {
        margin-top: 0;
      }
    }
  }

  .copyright {
    font-size: 12px;
    color: #979797;
  }

  .social-links {
    margin-bottom: 30px;
    a {
      color: #979797;
      font-size: 15px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  ul {
    list-style: none;

    &.languages {
      li {
        display: inline-block;
        &:after {
          margin: 0 10px;
          content: " • ";
        }
        &:last-child {
          padding-right: 0;
          &::after {
            content: "";
          }
        }
        a {
          color: #979797;
          font-size: 15px;
          margin-right: 30px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

/*------------------------
    Contact Page
--------------------------*/
.contact {
  h1 {
    text-align: center;
    margin-top: 0;
  }

  .contact-form {
    margin: 50px auto;
    padding: 20px;

    input[type="text"],
    textarea {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      display: block;
      outline: none;
      border: none;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      padding: 0;
      padding-left: 20px;
    }

    textarea {
      height: 200px;
    }

    .outline {
      display: block;
      padding: 9px;
      border: 1px solid #dddddd;
      margin-bottom: 30px;
      border-radius: 3px;
      label {
        display: block;
        float: left;
        height: 23px;
        margin-top: -25px;
        background: #fff;
        padding: 2px 5px 2px 5px;
        color: #b9b9b9;
        font-size: 14px;
        overflow: hidden;
        &.danger {
          color: #721c24;
          font-size: 14px;
          margin-top: 25px;
          padding: 0;
        }
      }
    }

    .btn-submit {
      display: inline-block;
      font-size: 14px;
      min-width: 140px;
      text-align: center;
      padding: 15px 10px;
      position: relative;
      color: #fff;
      border: none;
      background-color: #000;
      margin-right: 15px;
      &:active{
        background: #979797;
        color: #000;
      }
    }
  }
}

/*-----------------------
NOT FOUND
-----------------------*/
#notfound-page {
  position: relative;
  height: 100vh;
  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    .notfound-404 {
      position: relative;
      margin: 0px auto 20px;
      z-index: -1;
      h1 {
        font-size: 66px;
        font-weight: 700;
        margin: 0px;
        color: #211b19;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      h2 {
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        color: #211b19;
        background: #fff;
        padding: 10px 5px;
        margin: auto;
        display: inline-block;
      }
      a {
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        padding: 13px 23px;
        background: #979797;
        font-size: 18px;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        &:hover {
          color: #979797;
          background: #211b19;
        }
      }
    }
  }
}

/* ===========================
  Responsive
==============================*/

@media only screen and (min-width: 576px) {
  .container {
    &.about-us {
      .description {
        img {
          float: right;
          padding-left: 15px;
        }
      }
    }
  }
}

/* Medium screen : 992px. */
@media only screen and (min-width: 992px) {
  .site-logo {
    width: 250px;
  }
}

/* Tablet :768px. */
@media only screen and (min-width: 768px) {
  .header-section {
    .site-logo {
      width: 150px;
    }
  }
  .container {
    margin-top: 130px;
  }
  .about-us {
    .description {
      img {
        padding-bottom: 0;
        padding-right: 0;
      }
    }
  }
}

/* Large Mobile :480px. */
@media only screen and (max-width: 767px) {
  .main-menu {
    position: absolute;
    background: #f8f8f8;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 71px;
    padding: 0;
    display: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    ul {
      display: block;
      li {
        display: block;
        padding: 15px 0;
        border-bottom: 1px solid #ddd;
        a {
          // padding: 15px;
          // // border-bottom: 1px solid #ddd;
          // display: block;
          // margin: 0;
          display: inline-flex;
          align-items: center;
          &.active {
            // border-top-color: #001418;
            // border-top-style: solid;
            // border-top-width: 1px;
            border-bottom: none;
            font-weight: bold;
            // border-bottom-color: #001418;
            // border-bottom-style: solid;
            // border-bottom-width: 1px;
          }
        }
        &:last-child {
          border-bottom: none;
          a {
            &.active {
              // border-top-color: #001418;
              // border-top-style: solid;
              // border-top-width: 1px;
              border-bottom-color: #001418;
              border-bottom-style: solid;
              border-bottom-width: 1px;
            }
          }
        }
      }
    }
  }

  .nav-switch {
    position: absolute;
    left: 25px;
    font-size: 30px;
    color: #333;
    top: 23px;
    display: block;
  }
  .header-section {
    .site-logo {
      img {
        width: 130px;
        height: 60px;
      }
    }
  }
  .header-btn {
    display: none;
  }

  // .section-title {
  //   font-size: 45px;
  //   margin: 100px 0;
  // }

  .container {
    //margin-top: 100px;
    &.home {
      margin-top: 70px;
      max-width: 100%;
      padding: 0;
      .media {
        display: flex;
        align-items: center;
        overflow: visible;
        max-width: 100%;

        .videoPlayer {
          height: 100%;
          width: 100%;
        }
      }
    }

    h1 {
      // font-size: 30px;
      &.section-title {
        // margin: 100px 0 0 0;
        span {
          display: block;
          text-align: center;
          color: #979797;
        }
      }
    }
  }

  .footer-section {
    margin-top: 0;
    .container {
      margin-top: 0;
    }
  }

  .contact {
    .contact-form {
      .btn-submit {
        width: 100%;
      }
    }
  }
}
